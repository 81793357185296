/// Menu
import MetisMenu from "metismenujs";
import React, { useEffect, useRef } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";

const MM = ({ children }) => {
  const elRef = useRef(null);

  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const mm = new MetisMenu(el);
    }
  }, []);

  return (
    <div className="mm-wrapper">
      <ul className="metismenu" ref={elRef}>
        {children}
      </ul>
    </div>
  );
};

const SideBar = () => {
  const wrapperRef = useRef(null);
  const elRef = useRef(null);

  useEffect(() => {
    const mainWrapper = document.querySelector("#main-wrapper");

    // Add click event listener to handle outside clicks
    const handleClickOutside = (event) => {
      if (window.innerWidth < 1300) {
        const deznav = document.querySelector(".deznav");
        const navControl = document.querySelector(".nav-control");
        
        // Check if click is outside sidebar and nav control
        if (!deznav.contains(event.target) && !navControl.contains(event.target)) {
          if (mainWrapper.classList.contains("menu-toggle")) {
            mainWrapper.classList.remove("menu-toggle");
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    const dashboardElements = [
      ".winery-management",
      ".booking-management",
      ".dashboard"
    ];

    if (window.innerWidth < 1300) {
      dashboardElements.forEach((selector) => {
        const btnElement = document.querySelector(selector);
        if (btnElement) {
          btnElement.addEventListener("click", () => {
            mainWrapper.classList.remove("menu-toggle");
          });
        }
      });
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      dashboardElements.forEach((selector) => {
        const btnElement = document.querySelector(selector);
        if (btnElement) {
          btnElement.removeEventListener("click", () => {
            mainWrapper.classList.remove("menu-toggle");
          });
        }
      });
    };
  }, []);

  const closeSidebar = () => {
    if (window.innerWidth < 1300) {
      const wrapper = document.querySelector("#main-wrapper");
      if (wrapper.classList.contains("menu-toggle")) {
        wrapper.classList.remove("menu-toggle");
      }
    }
  };

  let path = window.location.pathname.split("/").pop();

  const isDashboardActive = path === "" || path === "dashboard";


  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu" ref={elRef}>
          <li className={`dashboard ${isDashboardActive ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/" onClick={closeSidebar}>
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={` winery-management ${path === "winery-management" ? "mm-active" : ""}`}>
            <Link to="winery-management" className="ai-icon" onClick={closeSidebar}>
              <i className="flaticon-381-photo"></i>
              <span className="nav-text">Experience management</span>
            </Link>
          </li>
          <li className={`booking-management ${path === "booking-management" ? "mm-active" : ""}`}>
            <Link to="booking-management" className="ai-icon" onClick={closeSidebar}>
              <i className="flaticon-381-bookmark-1"></i>
              <span className="nav-text">Bookings</span>
            </Link>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
