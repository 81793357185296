import React, { useState, useEffect } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/Logo/Original Logo Symbol.png";
import logoText from "../../../images/Logo/Transparent Logo.png";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);

   useEffect(() => {
      // Listen for menu-toggle class changes on main-wrapper
      const mainWrapper = document.querySelector("#main-wrapper");
      const observer = new MutationObserver((mutations) => {
         mutations.forEach((mutation) => {
            if (mutation.attributeName === 'class') {
               setToggle(mainWrapper.classList.contains('menu-toggle'));
            }
         });
      });

      observer.observe(mainWrapper, {
         attributes: true
      });

      return () => observer.disconnect();
   }, []);

   const handleToggle = (e) => {
      e.stopPropagation(); // Prevent event bubbling
      const mainWrapper = document.querySelector("#main-wrapper");
      mainWrapper.classList.toggle("menu-toggle");
   };

   return (
      <div className="nav-header" style={{zIndex: "12"}}>
         <Link to="/" className="brand-logo">
            <img className="logo-compact ml-5" src={logoText} alt="" />
            <img className="brand-title ml-3" src={logoText} alt="" />
         </Link>

         <div className="nav-control" onClick={handleToggle}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
